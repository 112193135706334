import React, { useEffect, useState } from "react";
import logo from "../../../img/logo.svg";
import { NavDropdown, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const PublicTopNavBar = () => {
  let navigate = useNavigate();
  const userProfile = useSelector((state) => state.auth.userProfile);

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <header className="dash-header-wrap">
      <nav className="navbar navbar-expand-xl fixed-top bg-white shadow">
        <div className="container-fluid">
          <a className="navbar-brand" href="javascript:void(0)">
            <img src={logo} alt="Logo" className="nav-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="ki-solid ki-burger-menu-5 text-primary"></i>
          </button>

          <div className="navbar-right order-xl-3">
            {Object.keys(userProfile).length > 0 ? (
              <a
                href="javascript:void(0)"
                title="Login"
                className="btn btn-link d-none d-md-inline-block"
                onClick={() => navigate("/dashboard")}
              >
                Dashboard <i className="fa-solid fa-arrow-right"></i>
              </a>
            ) : (
              <>
                <a
                  href="javascript:void(0)"
                  title="Login"
                  className="btn btn-link d-none d-md-inline-block"
                  onClick={() => navigate("/login")}
                >
                  Login <i className="fa-solid fa-arrow-right"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  title="Signup"
                  className="btn btn-primary"
                  onClick={() => navigate("/register")}
                >
                  Sign Up <i className="fa-solid fa-arrow-right"></i>
                </a>
              </>
            )}
          </div>
          <div
            className="collapse navbar-collapse pt-2 pt-xl-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <Nav.Item className="">
                <Nav.Link>
                  <Link to={"/home"}>Home</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link>
                  <Link to={"/speciality"}>Specialities</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link>
                  <Link to={"/services"}>Services</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link>
                  <Link to={"/gallery"}>Gallery</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link>
                  <Link to={"/about"}>About Us</Link>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link>
                  <Link to={"/contact"}>Contact Us</Link>
                </Nav.Link>
              </Nav.Item>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default PublicTopNavBar;
