import { toast } from "react-toastify";
import * as api from "../../services/api";
import { doPayment } from "../../utils/payment";
import {
  GET_ALL_CONFERENCE,
  GET_ALL_CONFERENCE_BY_FILTER,
  GET_ALL_CONFERENCE_BY_ID,
  ADD_CONFERENCE,
  ATTEND_CONFERENCE,
  MY_CONFERENCE,
  GET_CONFERENCE_BY_ID,
  GET_ASSOCIATION_LIVE_CONFERENCE
} from "../constants/conferenceActionTypes";
import { CLEAR_LOADING, SET_LOADING } from "../constants/globalActionTypes";

export const getAllConference = () => async (dispatch) => {
  try {
    const result = await api.getAllConference();

    dispatch({ type: GET_ALL_CONFERENCE, payload: result.data.data.data });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const getAllConferenceByFilter =
  (data, reset = false) =>
  async (dispatch) => {
    try {
      const result = await api.getAllConferenceByFilter(data);
      console.log("result", result)
      dispatch({
        type: GET_ALL_CONFERENCE_BY_FILTER,
        payload: result.data.data.docs,
        hasMore: result.data.data.hasNextPage,
        reset: reset,
      });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      console.log(error.message);
      dispatch({ type: CLEAR_LOADING });
    }
  };
export const getAllConferenceShipById = (data) => async (dispatch) => {
  try {
    const result = await api.getConferenceById(data);

    dispatch({ type: GET_CONFERENCE_BY_ID, payload: result.data.data[0] });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const registerToConference = (data) => async (dispatch) => {
  try {
    const result = await api.registerConterence(data);
    console.log("register data", result);
    if (result) {
      let request = result.data.data;
      request.type = "CONFERENCE";
      await doPayment(request);
    }
  } catch (error) {
    console.log(error.message);
  }
};

export const attendConference = (data) => async (dispatch) => {
  try {
    const result = await api.attendConference(data);
    dispatch({ type: ATTEND_CONFERENCE, payload: result.data.data.docs });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const myConference = (data) => async (dispatch) => {
  try {
    const result = await api.myConference(data);
    console.log(result);
    dispatch({ type: MY_CONFERENCE, payload: result.data.data.docs });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });
  }
};

export const addConference = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    let result = {};
    if (data.get("associationId")) {
      result = await api.postAssociationConference(data);
    } else {
      result = await api.addConference(data);
    }
    if (result) {

      if(result.data.status.success) {

          toast.success("Conference & Workshop Created Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({ type: CLEAR_LOADING });
    
          dispatch({ type: ADD_CONFERENCE, payload: data });
          if (data.get("associationId")) {
            navigate(`/myAssociationConf/${data.get("associationId")}`)
          } else {
            navigate('/myConfWorkshop')
          }
          return "success"
        
      } else {

        toast.error(result.data.status.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({ type: CLEAR_LOADING });

        return "error"
        
      }
    }
  } catch (error) {
   
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
export const updateConference = (data,navigate) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const result = await api.updateConference(data);
    if (result) {
      toast.success("Conference & Workshop Updated Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({ type: CLEAR_LOADING });

      dispatch({ type: ADD_CONFERENCE, payload: data });
      if (data.get("associationId")) {
        navigate(`/myAssociationConf/${data.get("associationId")}`)
      } else {
        navigate('/myConfWorkshop')
      }
      return "success"

    }
  } catch (error) {
    console.log(error.message);
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while saving data", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};



export const SendInvitation = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    let result = {};
    result = await api.SedBulkInvitation(data);

    if(result.data.status.success) {

        toast.success("Invitation Sent Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch({ type: CLEAR_LOADING });
  
        
        return "success"
      
    } else {

      toast.error(result.data.status.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({ type: CLEAR_LOADING });

      return "error"
      
    }
    
  } catch (error) {
   
    dispatch({ type: CLEAR_LOADING });

    toast.error("Error while sending invitation", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};


export const getLiveEvents = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.getLiveEvents(data);

    dispatch({
      type: GET_ASSOCIATION_LIVE_CONFERENCE,
      payload: result.data.data.docs,
    });
    dispatch({ type: CLEAR_LOADING });
   
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error.message);
  }
};

export const registerLiveEvents = (data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const result = await api.registerLiveEvents(data);
    if (result.data.status.success) {
      let request = result.data.data;
      dispatch({ type: CLEAR_LOADING });
      return request;
      // request.type = "CONFERENCE";
      // await doPayment(request);
    } else {
      toast.error("Error while sending Registeration", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch({ type: CLEAR_LOADING });
   
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    toast.error("Error while sending Registeration", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  }
};